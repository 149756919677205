import type Field from 'o365.modules.DataObject.Field.ts';

export enum SortDirection {
    Desc = 'desc',
    Asc = 'asc'
}

export enum GroupByAggregates {
    Avg = "AVG",
    Count = "COUNT",
    Count_Distinct = "COUNT_DISTINCT",
    Sum = "SUM",
    Max = "MAX",
    Min = "MIN"
}

export interface ISortColumns {
    name?: string;
    sortOrder: number;
    sortDirection: SortDirection;
};

export interface IGroupByColumns {
    name: string;
    groupByOrder: number;
};

export interface IGroupByAggregates {
    name: string;
    groupByAggreGate
};

declare class DataObjectFieldUtilities {
    static getSortColumns(fields: Array<Field>): Array<ISortColumns>;
    static getGroupByAggregates(fields: Array<Field>): Array<IGroupByAggregates>;
    static getGroupByColumns(fields: Array<Field>): Array<IGroupByColumns>;
    static getSortingArray(sortColumns: Array<ISortColumns>): Array<any>; // Replace 'any' with the actual return type if needed
}

export type { DataObjectFieldUtilities };